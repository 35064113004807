@define-mixin above $width: $small {
  @media screen and (min-width: $width){
    @mixin-content;
  }
}

@define-mixin below $width: $small {
  @media screen and (max-width: $width){
    @mixin-content;
  }
}

@define-mixin antialiased {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@define-mixin util $name {
  .u-$(name) {
    @mixin-content;
  }
}

  /* .u-sm-$(name) {
    @mixin above $small {
      @mixin-content;
    }
  }

  .u-md-$(name) {
    @mixin above $medium {
      @mixin-content;
    }
  }

  .u-lg-$(name) {
    @mixin above $large {
      @mixin-content;
    }
  }
} */

/* Header Mixins */

@define-mixin h5 {
  @mixin phosphate;
  font-size: .825rem;
  line-height: 1;
  text-transform: uppercase;
  strong {
    @mixin phosphate;
  }
}

@define-mixin h6 {
  @mixin gordita-medium;
  font-size: 1rem;
  line-height: 1;
  margin: 0.5rem 0;
}

/* Form Mixins */

@define-mixin rounded-input {
  @mixin gordita-regular;
  background: white;
  border-radius: $radius;
  display: block;
  border: 1px solid #CCC9C1;
  padding: 0.5em 0.75em .25rem;
  line-height: 1;
  transition: border 200ms;
  margin-bottom: 0;
  height: 3rem;
  width: 100%;

  &:focus {
    border-color: #807D75;
  }

  &::placeholder {
    color: $otherGrey;
  }

  @mixin above $small {
    font-size: 0.9375rem;
  }
}
