/* Colors */
$black: #433E36;
$lightGrey: #FAFAFA;
$grey: #ECEDED;
$mediumGrey: #D8E0E5;
$mediumDarkGrey: #C2C9CE;
$darkGrey: #303030;
$otherGrey: #8A909B;
$yellow: #FFC457;
$lightYellow: #ffe7bb;
$orange: #FF7857;
$red: #d6320a;
$blue: #28A5FF;
$hoverBlue: #256CB4;
$green: #41BE94;

/* Max Widths */
$max-width: 1680px;

/* Breakpoints */
$small: 732px;
$medium: 900px;
$large: 1100px;
$map-breakpoint: 1080px;

/* Padding and margins */
$global-padding: 1rem;
$radius: 4px;
$nav-height: 56px;

/* Font sizing */
$base-line-height: 1.75rem;

/* Timing */
$hover-speed: 150ms;

