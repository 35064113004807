@define-mixin phosphate {
  font-family: "phosphatepro-solid", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

@define-mixin gordita-regular {
  font-family: "gordita-regular", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

@define-mixin gordita-medium {
  font-family: "gordita-medium", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

@define-mixin gordita-bold {
  font-family: "gordita-bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
