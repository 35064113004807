@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.Hero {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: content-box;
  position: relative;
  text-align: center;

  @mixin above $medium {
    background-position: right center;
    background-size: 85%;
    height: 460px;
    text-align: left;
  }
}

.Hero_content {
  margin: 0 auto;
  margin-bottom: 1rem;

  p {
    font-size: 17px;
    line-height: 2rem;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @mixin above $medium {
    background-color: white;
    box-sizing: border-box;
    color: black;
    margin-bottom: 0;
    max-width: 600px;
    padding: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    h1, h2, h3, h4, h5, p {
      color: black;
    }
  }
}

.Hero_photo {
  @mixin above $medium {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 85%;
  }
}

.TruckHero_photos {
  @mixin above $medium {
    position: absolute;
    right: 0;
    width: 85%;
  }
}

.Hero_imageContainer {
  position: relative;
  height: 200px;

  @mixin above $small {
    height: 300px;
  }

  @mixin above $medium {
    height: 460px;
  }
}

.Hero_image {
  sition: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
